






























































$topbarHeight:56px;
.container{
    height: 100%;
    font-size: 12px;
    background:#F3F4F6 ;
    .content{
        width:100%;
        clear: both;
        // display: flex;
        height: calc(100% - #{$topbarHeight});
        background: #ffffff;
        overflow: hidden;
        // align-items: stretch;
    }
}
     @media (min-width: 1968px){
        .content{
            width:1968px!important;
            margin:0 auto;
        }
    }
