




























































































































.side-bar{
     width:222px;
     border-right: 1px solid #EDEEF0;
     height: 100%;
     padding:0 14px;
     box-sizing: border-box;
     overflow-y: auto;
     float: left;
    .channel-sidebar{
        box-sizing: border-box;
        width:100%;
        padding:24px 0 16px 0;
        min-height: 488px;
        .channel {
            padding-left: 16px;
            height: 22px;
            line-height: 22px;
            margin:16px 0;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            // &:not(:last-of-type){
            //     margin-bottom: 16px;
            // }
            .channel-icon{
                width:20px;
                height: 20px;
                margin-right: 24px;
                vertical-align: middle;
                cursor: pointer;
            }
            .channel-title{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                line-height: 22px;
                color: #1F2229;
                letter-spacing: 0;
                font-weight: 400;
                position: relative;
                width:43px;
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
                border-radius: 4px;
                cursor: pointer;
                z-index:0;
                .active-box{
                position: absolute;
                width:43px;
                height: 8px;
                z-index: -1;
                top:15px;
                left:-2px;
                border-radius: 4px;
                overflow: hidden;
                .active{
                    position: absolute;
                    width:100%;
                    height: 100%;
                    display: inline-block;
                    background: #FFDEE0;
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#FFDEE0, endColorstr=#EEE0FF);/*IE<9>*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#FFDEE0, endColorstr=#EEE0FF)";/*IE8+*/
                background-image:linear-gradient(269deg, #FFF2CC 0%, #FFDEE0 50%, #EEE0FF 100%);
                }
                }
            }
        }

    }
    .line{
        // width:174px;
        height: 1px;
        background: #EDEEF0;
        // margin:0 24px;
     }
     .policy{
        padding:24px 0  26px 0px;
        .policy-item{
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #4C5059;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 400;
            cursor: pointer;
            margin-bottom:8px;
            &:hover{
                opacity: 0.8;
            }
        }
        .copyright{
            font-family: PingFangSC-Regular;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 400;
            color: #999DA6;
            margin-bottom: 8px;
        }
        .icon-police{
            background-image: url("https://pic2.iqiyipic.com/lequ/20250107/icon-police.png");
            background-size: contain;
            display: inline-block;
            height: 16px;
            width: 14px;
            margin-right: 6px;
            margin-top: -4px;
            vertical-align: middle;
        }
     }
}
