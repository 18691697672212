
































































































































     .mask{
        position: fixed;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        right: 0;
        bottom:0;
        z-index: 10;
        background: rgba(0,0,0,0.4);
        .contact-content{
            position: absolute;
            cursor: pointer;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            width: 525px;
            height: 384px;
            padding:24px;
            box-sizing: border-box;
            z-index: 11;
            background: #FFFFFF;
            border-radius: 16px;
              .cancel{
                position: absolute;
                right: 16px;
                top: 16px;
                width: 16px;
                height: 16px;
            }
            .title{
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: #1F2229;
                letter-spacing: 0;
                text-align: center;
                font-weight: 600;
                margin-bottom: 12px;
            }
            .introduce{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #1F2229;
                letter-spacing: 0;
                text-align: justify;
                font-weight: 400;
                margin-bottom: 24px;
            }
            .contact-ways{
                clear:both;
                .contact-way{
                    width:50%;
                    float:left;
                    .sub-title{
                        margin-bottom: 7px;
                        .sub-logo{
                            width:20px;
                            height: 20px;
                            vertical-align: middle;
                            margin-right: 8px;
                        }
                        .title-words{
                            font-family: PingFangSC-Medium;
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 14px;
                            color: #1F2229;
                            letter-spacing: 0;
                            text-align: justify;
                            font-weight: 500;
                        }
                    }
                    .sub-content{
                        height: 80px;
                        line-height: 20px;
                        margin-left: 28px;
                        font-family: PingFangSC-Light;
                        font-size: 12px;
                        color: #1F2229;
                        letter-spacing: 0;
                        font-weight: 200;
                        .wechat_img,.webo_img{
                            width:80px;
                            height: 80px;
                            margin-left: -4px;
                        }
                    }
                }
            }
        }
    }
    .top-bgd{
         width:100%;
        background: #FFFFFF;
    .top-container{
        width:100%;
        max-width: 1968px;
        height: 56px;
        margin:0 auto;
        box-shadow: inset 0 -1px 0 0 #EDEEF0;
        padding-left:40px;
        box-sizing: border-box;
        .logo{
            width: 74px;
            height: 48px;
            vertical-align: middle;
        }
        .tab-content{
            margin-left:140px;
             width:calc(100% - 254px);
             display: inline-block;
             vertical-align: middle;
            .tab-box{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin: 0 12px;

                .tab-parent{
                    overflow: hidden;
                    border-radius: 16px;
                .tab{
                    padding:6px 16px;
                    color: #1F2229;
                    font-size: 14px;
                    display: inline-block;
                    &:hover{
                        opacity: 0.8;
                        cursor:pointer;
                        color:#fff;
                        border-radius: 16px;
                        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#FF325F, endColorstr=#FA1E2D);/*IE<9>*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#FF325F, endColorstr=#FA1E2D)";/*IE8+*/
                        background-image: linear-gradient(225deg, #FF325F 0%, #FA1E2D 100%);
                    }
                }
                }
                .QRCode-content{
                    position: absolute;
                    z-index: 2;
                    width:216px;
                    height: 246px;
                    margin-top:16px;
                    left: 0;
                    box-sizing: border-box;
                    border-radius: 10px;
                    background: #FFFFFF;
                    border: 1px solid #EDEEF0;
                    box-shadow: 0 2px 8px 0 rgba(31,34,41,0.10);
                    .QRCode{
                        width:124px;
                        height: 124px;
                        margin:34px auto 16px;
                        display: block;
                    }
                    .QRCode-msg{
                        font-family: PingFangSC-Semibold;
                        font-size: 14px;
                        color: #1F2229;
                        letter-spacing: 0;
                        text-align: center;
                        font-weight: 600;
                    }
                }
            }

        }
    }
    }
