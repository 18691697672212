





































































































































































































.longVideo{
    padding:24px;
    float: left;
    width:calc(100% - 270px);
    height: 100%;
    overflow-y: auto;
    // display: flex;
    // flex-flow: wrap;
    .video-content{
        // width:32%;
        float:left;
         &:hover{
            opacity: 0.8;
        }
        .seo-a{
            display: block;
        }
        // &:nth-of-type(3n-1){
        //     margin:0 2%;
        // }
        .image-box{
            width:100%;
            height: 0;
            padding-bottom: 56.25%;
            background:#eee;
            position: relative;
            overflow: hidden;
            .video-image{
                width:100%;
                height: auto;
            }
            .episode{
                position: absolute;
                right: 10px;
                bottom:10px;
                color:#FFFFFF;
                font-family: PingFangSC-Regular;
                font-size: 14px;
            }
        }
        .video-msg{
            padding:12px 0 24px 0 ;
            .video-title{
                opacity: 0.8;
                font-family: PingFangSC-Semibold;
                font-size: 14px;
                color: #1F2229;
                word-wrap: break-word;
                letter-spacing: 0;
                line-height: 22px;
                font-weight: 600;
            }
            .introduce-msg{
                width:100%;
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #999DA6;
                letter-spacing: 0;
                // line-height: 12px;
                font-weight: 400;
                margin-top: 12px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            }
        }
    }
    .tip-box{
        width:100%;
        clear: both;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .loading-box{
            width:100%;
            .sk-loading{
            text-align: center;
            margin:30px auto;
            width:112px;
            position: relative;
            overflow: hidden;
            }
        }
        .noData{
            text-align: center;
            font-size: 14px;
            width:112px;
            margin:20px auto;
        }
    }
}
@media(max-width: 1160px){
    .longVideo{
        .video-content{
            width:32%;
            &:nth-of-type(3n-1){
                margin:0 2%;
            }
            &:nth-of-type(3n-2){
                clear:both;
            }
        }
    }
}
 @media (min-width: 1161px) and (max-width: 1967px){
    .longVideo{
        .video-content{
            width:24%;
            margin-right:1.3%;
            &:nth-of-type(4n){
                margin-right: 0;
            }
            &:nth-of-type(4n-3){
                clear:both;
            }
        }
    }
}
@media (min-width: 1968px){
    .longVideo{
        .video-content{
            width:19%;
            margin-right:1.2%;
            &:nth-of-type(5n){
                margin-right: 0;
            }
            &:nth-of-type(5n-4){
                clear:both;
            }
        }
    }
}
